<template>
  <v-container>

    <h1 class="mb-4 pb-4">{{$t('qp6QBbS7sNDo1XKl1s2OB')}}</h1>

    <!-- <p class="pb-6">{{ $t('MuUCXmBvqy0T9bkWT5-eQ') }}</p> -->

    <p class="pb-6">{{ $t('f3oCH_m5OFMDCtOjYFPCA') }}</p>

    <v-layout>
      <v-row class="d-flex justify-center">

        <ReportList />

      </v-row>
    </v-layout>

    <OfferModal :modal="showOffer" />

  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import OfferModal from '@/components/OfferModal'
import ReportList from '@/components/ReportList'
import { getCookie, setCookie } from '@/plugins/cookies'

export default {
  components: {
    ReportList, OfferModal
  },
  data: () => ({

  }),

  computed: {
    ...mapGetters(['loading', 'isLogin', 'userReports']),

    showOffer () {
      if (this.isLogin && !getCookie('offer') && this.userReports.length === 0) {
        setCookie('offer', 1, {
          'max-age': 60 * 60 * 24 * 60
        })
        return true
      }
      return false
    },
  },

  methods: {
    ...mapActions(['setLoading']),
  },

  // async mounted () {
  //   this.setLoading(true)
  //   try {

  //     // обновляем баланс при открытии страницы, чтобы не было бага, когда не сразу обновляется
  //     await this.$store.dispatch('autoLogin')
  //   } catch (error) { console.log(error) }
  //   this.setLoading(false)
  // }

}
</script>