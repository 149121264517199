<template>
  <v-col
    cols="12"
    xl="3"
    lg="3"
    md="3"
    sm="6"
    xs="6"
  >

    <v-dialog
      persistent
      v-model="dialog"
      :fullscreen="windowWidth < 600 ? true : false"
      transition="scale-transition"
      :hide-overlay="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          large
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left>
            mdi-filter-plus
          </v-icon>
          {{ $t('Pgpr3-0OCmy_F4agBOM35') }}
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="white--text">{{ $t('Pgpr3-0OCmy_F4agBOM35') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="Save()"
            >
              {{ $t('qLNGGHc7P59QkvB7FnUo2') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-container
          fluid
          class="pt-10"
        >
          <v-row>

            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
              xs="6"
            >
              <v-text-field
                v-model="categories"
                clearable
                text
                solo-inverted
                hide-details="auto"
                prepend-inner-icon="mdi-tag-multiple-outline"
                :label="$t('qlGVXHsFa2dZjrsP29zor')"
                :messages="$t('Z-gyWwgzo-BNoKkm2bcma') + ' (English only!)'"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              xl="4"
              lg="6"
              md="6"
              sm="6"
              xs="6"
            >
              <v-text-field
                v-model="keywords"
                clearable
                text
                solo-inverted
                hide-details="auto"
                prepend-inner-icon="mdi-card-text-outline"
                :label="$t('zLzhnEGwtE0aYse1-_UWI')"
                :messages="$t('rUHZ6LhsAchG6561SN_r0')"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              xl="4"
              lg="6"
              md="6"
              sm="6"
              xs="6"
            >
              <v-autocomplete
                v-model="lang"
                return-object
                :items="getLanguages"
                item-text="text"
                item-value="value"
                text
                clearable
                solo-inverted
                prepend-inner-icon="mdi-translate"
                hide-details="auto"
                :label="$t('8PemBqxiSCNF7X8CKJ6uU')"
                :messages="$t('YWPbeLQZfEX5l_6UGNIMf')"
              ></v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              xl="4"
              lg="6"
              md="6"
              sm="6"
              xs="6"
            >
              <v-text-field
                v-model="tlds"
                clearable
                text
                solo-inverted
                hide-details="auto"
                prepend-inner-icon="mdi-web"
                :label="$t('uEQrnJ-Huq_3ypsgttP6Z')"
                :messages="$t('wh_rkOPlRMbjRg7tcomr7') +`: com, net, de, fr`"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              xl="2"
              lg="2"
              md="2"
              sm="2"
              xs="2"
            >
              <v-btn
                large
                text
                color="primary"
                dark
                block
                @click="Clear()"
              >
                {{ $t('0I_i_VluYLqCWtsxK8Ujk') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              xl="2"
              lg="2"
              md="2"
              sm="2"
              xs="2"
            >
              <v-btn
                large
                color="red"
                dark
                block
                @click="dialog = false"
              >
                {{ $t('gHAKKA1gMOXPna1OZbfk3') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              xl="2"
              lg="2"
              md="2"
              sm="2"
              xs="2"
            >
              <v-btn
                large
                color="primary"
                dark
                block
                @click="Save()"
              >
                {{ $t('qLNGGHc7P59QkvB7FnUo2') }}
              </v-btn>
            </v-col>

          </v-row>

        </v-container>

      </v-card>
    </v-dialog>

  </v-col>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,

      lang: null,
      categories: null,
      keywords: null,
      tlds: null
    }
  },

  methods: {
    ...mapActions(['getReportList', 'setReportFilter']),

    Save () {
      this.setReportFilter({
        categories: this.categories,
        keywords: this.keywords,
        tlds: this.tlds,
        lang: this.lang ? this.lang.value : ''
      })
      this.dialog = false
      this.getReportList()
    },

    Clear () {
      this.lang = null
      this.categories = null
      this.keywords = null
      this.tlds = null
    }
  },

  computed: {
    windowWidth () {
      return document.body.clientWidth
    },
    getLanguages () {
      let result = []
      for (const c in this.$root.languages) {
        result.push({
          text: `${this.$root.languages[c].name} (${this.$root.languages[c].native})`,
          value: c
        })
      }
      return result
    }
  }
}
</script>