<template>
  <v-container fluid>

    <template v-if="localLoading">
      <v-layout
        class="my-4"
        justify-center
        row
      >
        <v-row class="d-flex justify-center">
          <v-skeleton-loader
            v-for="f in [1,2,3,4,5,6,7,8]"
            :key="f"
            class="mx-auto ma-5"
            max-width="270px"
            min-width="270px"
            type="card"
          ></v-skeleton-loader>
        </v-row>
      </v-layout>
    </template>

    <v-data-iterator
      v-else
      :items="reportList"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      :sort-by="sortBy.value"
      :sort-desc="sortDesc"
      :loading="loading"
      :no-data-text="$t('u6UG9cg9vmPPtbwq-FT_S')"
      hide-default-footer
    >
      <template v-slot:no-data>
        <v-alert
          dense
          border="left"
          type="warning"
          class="my-5"
        >
          {{$t('u6UG9cg9vmPPtbwq-FT_S')}}
        </v-alert>
      </template>

      <template v-slot:loading>
        <v-layout
          class="my-4"
          justify-center
          row
        >
          <v-row class="d-flex justify-center">
            <v-skeleton-loader
              v-for="f in [1,2,3,4,5,6,7,8]"
              :key="f"
              class="mx-auto ma-5"
              max-width="270px"
              min-width="270px"
              type="card"
            ></v-skeleton-loader>
          </v-row>
        </v-layout>
      </template>
      <template v-slot:header>
        <v-row>

          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="6"
            xs="6"
          >
            <v-autocomplete
              v-model="selectMonth"
              return-object
              :items="monthsOfReports"
              item-text="text"
              item-value="value"
              prepend-inner-icon="mdi-calendar-range"
              text
              solo-inverted
              hide-details
              @change="updateReportList"
            ></v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="6"
            xs="6"
          >
            <v-autocomplete
              v-model="selectCountry"
              return-object
              :items="countriesOfReports"
              item-text="text"
              item-value="value"
              prepend-inner-icon="mdi-earth"
              text
              solo-inverted
              hide-details
              @change="updateReportList"
            ></v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="6"
            xs="6"
          >
            <v-text-field
              v-model="search"
              clearable
              text
              solo-inverted
              hide-details
              prepend-inner-icon="mdi-magnify"
              :label="$t('uLehR7yIp58WVqD-FjANc')"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            xl="3"
            lg="3"
            md="3"
            sm="6"
            xs="6"
          >
            <v-select
              v-model="sortBy"
              text
              solo-inverted
              hide-details
              return-object
              :items="keys"
              item-text="text"
              item-value="value"
              prepend-inner-icon="mdi-sort-alphabetical-variant"
              :label="$t('zBl8sa3MXGW_0VUXx_NjU')"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            xl="3"
            lg="3"
            md="3"
            sm="6"
            xs="6"
          >
            <v-btn-toggle
              v-model="sortDesc"
              mandatory
            >
              <v-btn
                large
                depressed
                color="primary"
                :value="true"
                :disabled="!Object.keys(sortBy).length"
              >
                <v-icon color="white">mdi-sort-descending</v-icon>
              </v-btn>
              <v-btn
                large
                depressed
                color="primary"
                :value="false"
                :disabled="!Object.keys(sortBy).length"
              >
                <v-icon color="white">mdi-sort-ascending</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>

          <ReportListFilter id="TopReportList" />

          <v-col cols="12">
            <!-- <p>{{reportFilter}}</p> -->

            <v-icon
              left
              class="text--disabled"
            >
              mdi-filter-outline
            </v-icon>

            <template v-for="(value,key) in reportFilter">
              <v-chip
                :key="key"
                v-if="value && value !== '' && value !== 'LATEST' && value !== {}"
                class="ma-2 text--disabled"
                outlined
              >
                <template v-if="key=='month'">
                  <v-icon left>
                    mdi-calendar
                  </v-icon>
                  {{ $moment(`${value}`).format('MMMM YYYY') }}
                </template>
                <template v-if="key=='country'">
                  <v-icon left>
                    mdi-earth
                  </v-icon>
                  {{ $root.flags[ $root.sw_countries[value].code ].name }}
                </template>
                <template v-if="key=='categories'">
                  <v-icon left>
                    mdi-tag-multiple-outline
                  </v-icon>
                  {{value}}
                </template>
                <template v-if="key=='keywords'">
                  <v-icon left>
                    mdi-card-text-outline
                  </v-icon>
                  {{value}}
                </template>
                <template v-if="key=='tlds'">
                  <v-icon left>
                    mdi-web
                  </v-icon>
                  {{value}}
                </template>
                <template v-if="key=='lang'">
                  <v-icon left>
                    mdi-translate
                  </v-icon>
                  {{ $root.languages[value].name }}
                </template>
              </v-chip>

            </template>

            <v-chip
              class="ma-2"
              color="orange darken-3"
              label
              link
              outlined
              @click="copyClipRssFeed()"
            >
              <v-icon left>
                mdi-rss
              </v-icon>
              RSS Feed
            </v-chip>

          </v-col>

        </v-row>
      </template>

      <template v-slot:default="prop">

        <v-row class="pt-6">
          <v-col
            v-for="item in prop.items"
            :key="item.report_id"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <router-link
              :to="`report/${item.report_id}`"
              class="text-decoration-none"
            >
              <v-card class="report-card">
                <v-card-title class="subheading font-weight-bold">
                  <span
                    :class="{ 'demo-lock' : item.demo}"
                    class="text-truncate"
                    style="max-width: 75%"
                    :title="item.demo ? '' : item.domain"
                  >
                    {{ item.domain }}
                  </span>

                  <sup
                    v-if="item.growth > 0"
                    class="pl-2 caption green--text"
                  >
                    +{{ item.growth }}%
                  </sup>
                  <sup
                    v-else
                    class="pl-2 caption red--text"
                  >
                    {{ item.growth }}%
                  </sup>

                </v-card-title>

                <!-- <router-link
                  :to="{ name: 'balance'}"
                  class="text-decoration-none"
                >
                  <v-icon
                    v-if="item.growth >= 200"
                    class="premium-report-icon"
                    color="warning"
                    :title="$t('DIPKSJyAdZCpj458_MfGR')"
                  >
                    mdi-diamond-stone
                  </v-icon>
                </router-link> -->

                <v-sparkline
                  :fill="false"
                  :smooth="18"
                  :value="sparklineEstimatedMonthlyVisits(item)"
                ></v-sparkline>

                <v-divider></v-divider>

                <v-card-text>

                  <div class="text--primary">
                    <v-icon
                      size="18"
                      left
                      color="grey"
                    >mdi-trending-up</v-icon>
                    {{ $n(parseInt(item.visits))}}
                    {{ $t('v52OKnYUOX3BWUPfgN0og') }}
                    {{ $moment(item.last_month).format('MMMM') }}
                  </div>

                  <div
                    class="inline-block text-truncate "
                    :style="windowWidth < 600 ? 'max-width: 320px;' : 'max-width: 270px;'"
                  >
                    <v-icon
                      left
                      color="grey"
                      size="18"
                      title="<title>"
                    >mdi-alpha-t-box-outline</v-icon>
                    <template>
                      <span
                        class="text--primary"
                        v-if="item['title']"
                        :title="item.demo ? null : item['title']"
                        :class="{ 'demo-lock' : item.demo}"
                      >
                        {{ item['title'] }}
                      </span>
                      <span
                        v-else
                        class="text--disabled"
                      >({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span>
                    </template>
                  </div>

                  <div
                    class="inline-block text-truncate"
                    :style="windowWidth < 600 ? 'max-width: 320px;' : 'max-width: 270px;'"
                  >
                    <v-icon
                      left
                      color="grey"
                      size="18"
                      title="<meta name='description'>"
                    >mdi-card-text-outline</v-icon>
                    <template>
                      <span
                        class="text--primary"
                        v-if="item['description']"
                        :title="item.demo ? null : item['description']"
                        :class="{ 'demo-lock' : item.demo}"
                      >
                        {{ item['description'] }}
                      </span>
                      <span
                        v-else
                        class="text--disabled"
                      >({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span>
                    </template>
                  </div>

                  <div
                    class="inline-block text-truncate"
                    :style="windowWidth < 600 ? 'max-width: 320px;' : 'max-width: 270px;'"
                  >
                    <v-icon
                      size="18"
                      left
                      color="grey"
                    >mdi-translate</v-icon>

                    <template>
                      <span
                        class="text--primary"
                        v-if="item['lang']"
                        :title="$root.languages[item.lang].name"
                      >
                        <template v-if="item.lang && $root.languages[item.lang]">
                          {{ $root.languages[item.lang].name }}
                        </template>
                        <template v-else-if="item.lang">{{ item['lang'] }}</template>
                      </span>
                      <span
                        v-else
                        class="text--disabled"
                      >({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span>
                    </template>

                    <v-icon
                      left
                      right
                      size="18"
                      color="grey"
                    >mdi-earth</v-icon>

                    <template>
                      <span
                        class="text--primary"
                        v-if="item['country']"
                        :title="$root.flags[ $root.sw_countries[item.country].code ].name"
                      >
                        <template v-if="item.country">
                          {{ $root.flags[ $root.sw_countries[item.country].code ].name }}
                          {{ $root.flags[ $root.sw_countries[item.country].code ].emoji }}
                        </template>
                      </span>
                      <span
                        v-else
                        class="text--disabled"
                      >({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span>
                    </template>

                  </div>

                  <div
                    class="inline-block text-truncate"
                    :style="windowWidth < 600 ? 'max-width: 320px;' : 'max-width: 270px;'"
                  >
                    <v-icon
                      left
                      color="grey"
                      size="18"
                    >mdi-tag-multiple-outline</v-icon>
                    <template>
                      <span
                        class="text--primary"
                        v-if="Object.keys(item['categories']).length"
                        :title="item['categories'].join(', ')"
                      >
                        {{ item['categories'].join(', ') }}
                      </span>
                      <span
                        v-else
                        class="text--disabled"
                      >({{ $t('yAOyOZipc3OsQbkjoTKUy') }})</span>
                    </template>
                  </div>

                </v-card-text>

              </v-card>
            </router-link>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row class="mt-2 d-flex">
          <v-col
            cols="12"
            xl="6"
            lg="6"
            md="6"
            sm="12"
            xs="12"
            align="left"
            justify="center"
          >
            <span class="grey--text">{{ $t('TPvj1ezoU1khpfPVH3DaJ') }}</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  color="primary"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            xl="6"
            lg="6"
            md="6"
            sm="12"
            xs="12"
            align="right"
            justify="center"
          >
            <span class="mr-4
            grey--text">
              {{ $t('DvCR_GZrtudgtLWiM37iv') }} {{ page }} / {{ numberOfPages }}
            </span>
            <v-btn
              :disabled="numberOfPages===1 || page===1"
              fab
              dark
              color="primary"
              class="mr-1"
              @click="formerPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              :disabled="numberOfPages===1 || numberOfPages===page || numberOfPages===0"
              fab
              dark
              color="primary"
              class="ml-1"
              @click="nextPage"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>

        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import ReportListFilter from '@/components/ReportListFilter.vue'
import goTo from 'vuetify/es5/services/goto'
import config from '@/config'
import { mapGetters, mapActions } from 'vuex'
import md5 from 'js-md5'

export default {

  components: {
    ReportListFilter
  },

  data () {
    return {
      localLoading: true,
      selectCountry: {},
      selectMonth: {},
      itemsPerPageArray: [10, 50, 100],
      search: '',
      filter: {},
      sortDesc: true,
      page: 1,
      itemsPerPage: 10,
      sortBy: {
      },
      keys: [
        {
          text: this.$t('m6gXY5JVTjL5R48aQCWUx'),
          value: 'growth'
        },
        {
          text: this.$t('4iSJXijIPBtX7OOe6Ei7q'),
          value: 'visits'
        },
        {
          text: this.$t('kjha_8SLkiv3485-qIWAp'),
          value: 'title'
        },
        {
          text: this.$t('kc0wnSy2W3j6bM-EZSQVn'),
          value: 'description'
        },
        {
          text: this.$t('_-rn8eIgTfyw2j10Y42Ym'),
          value: 'lang'
        },
        {
          text: this.$t('kQdeua438STaUML20XIbb'),
          value: 'country'
        },
        {
          text: this.$t('7BqWPh8pNGHyTbilFkVSu'),
          value: 'categories'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['reportList', 'loading', 'user', 'reportFilter']),

    windowWidth () {
      return document.body.clientWidth
    },

    setSelectCountry () {
      /**
       * Для автоматического выбора страны в форме
       */
      const country = this.reportFilter.country || this.$root.country
      let countryCode = null
      Object.entries(config.data.sw_countries).forEach(
        ([key, value]) => {
          if (value.code === country) {
            countryCode = key
          }
        }
      )
      return this.countriesOfReports.find(c => { return c.value === countryCode }) ||
        this.countriesOfReports.find(c => { return c.value === '840' }) // по умолчанию код US

    },
    setSelectMonth () {
      /**
       * Для автоматического месяца страны в форме
       */
      const month = this.reportFilter.month || this.monthsOfReports[this.monthsOfReports.length - 1].value
      return this.monthsOfReports.find(c => { return c.value === month })
    },

    numberOfPages () {
      return Math.ceil(this.reportList.length / this.itemsPerPage)
    },
    filteredKeys () {
      return this.keys.filter(key => key.value !== 'name')
    },
    countriesOfReports () {
      /**
       * Список стран для заполнения формы вначале
       */
      let result = []
      result.push({
        text: this.$t('xLRFa2zd3v3-gxB17Lx8-') + " 🌏",
        value: 0
      })
      for (const c in config.data.sw_countries) {
        result.push({
          text: `${this.$root.flags[config.data.sw_countries[c].code].name} ${this.$root.flags[config.data.sw_countries[c].code].emoji}`,
          value: c
        })
      }
      return result
    },
    monthsOfReports () {
      /** 
       * Возвращает список месяцев с первого месяца отчетов для заполнения формы вначале
       */
      let ar = [];

      let start = this.$moment(this.$root.firstReportMonth, "YYYY-MM");
      let end = this.$moment().subtract(2, 'month')
      for (end; start.isBefore(end); start.add(1, 'month')) {
        ar.push({
          text: start.format('YYYY MMMM'),
          value: start.format('YYYY-MM')
        })
      }
      end.add(1, 'month')
      ar.push({
        text: end.format('YYYY MMMM') + ' 💤 ' + this.$t('G8YuIgcUNZPg875yJ5NdB'),
        value: end.format('YYYY-MM')
      })
      ar.push({
        text: '⚡️ ' + this.$t('a2z4kodweseh1PpHdNgzn'),
        value: 'LATEST'
      })
      return ar;
    }
  },

  async mounted () {
    this.selectCountry = this.setSelectCountry
    this.selectMonth = this.setSelectMonth
    await this.updateReportList()
    this.localLoading = false
  },

  methods: {
    ...mapActions(['getReportList', 'setReportFilter']),

    sparklineEstimatedMonthlyVisits (item) {
      let result = []
      for (const m of Object.values(item.EstimatedMonthlyVisits)) {
        if (m === null) {
          result.push(0)
          continue
        }
        result.push(m)
      }
      return result
    },

    async updateReportList () {
      await this.setReportFilter({
        month: this.selectMonth.value,
        country: this.selectCountry.value
      })
      await this.getReportList()
    },

    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
      goTo('#TopReportList')
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
      goTo('#TopReportList')
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
    },

    copyClipRssFeed () {
      let str = "";
      for (var key in this.reportFilter) {
        const val = encodeURIComponent(this.reportFilter[key])
        // пустые значения и параметр месяца пропускаем (чтобы фильтровать все новые)
        if (!val || key === 'month' || val === '0' || val === 'null') continue
        if (str != "") {
          str += "&";
        }
        str += (key + "=" + val);
      }
      // добавляю токен из мд5 почты и строки запроса + добавляю юзерайди для раскодировки на сервере
      // только если юзер залогинен
      if (this.user.email) {
        str = str + '&t=' + md5(this.user.email + str) + '&u=' + this.user.id
      }

      str = `https://${this.$root.domain}/feed/?` + str

      const area = document.createElement('textarea')
      document.body.appendChild(area)
      area.value = str
      area.select()
      document.execCommand("copy")
      document.body.removeChild(area)

      this.$store.dispatch('setMessage', { text: this.$t('-jqKgIyKzEfdRFIS6h97M'), type: 'success' })
    }

  },
}
</script>

<style scoped>
.premium-report-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
}
</style>