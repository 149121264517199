<template>
  <v-dialog
    v-model="modal"
    max-width="300px"
  >
    <v-card
      max-width="300"
      class="text-center"
      elevation="0"
    >

      <v-card-title>
        {{ $t('69k2N_S9GOd8M3niXGlGx') }} 🔥
      </v-card-title>

      <v-card-text>
        <div>{{$t('KggrHnYm0-EeUW6mcf4Pb')}} </div>
        <p class="subtitle-1 text-uppercase text--primary">
          {{tariff.months}} {{$t('GaOXHLBvLOfLpfk6ywNV1')}}
        </p>

        <div>
          <span class="display-1 text-uppercase text--primary font-weight-bold">
            ${{ ($root.tariffs[1].price / $root.tariffs[1].months).toFixed(2) }}
          </span>
          <span>/{{$t('0b6sxs-o8iyCo-1_3uN-e')}}</span>
        </div>

        <div v-if="i !== 2"><del class="text-uppercase text--disabled text-h5">
            ${{ ($root.tariffs[0].price / $root.tariffs[0].months).toFixed(2) }}
          </del></div>

        <v-chip
          @click.prevent="openPay(tariff,i)"
          v-if="i < 2"
          class="ma-2"
          :class="[{'highlight': i>=0}]"
          :color="i===1 ? 'green' : 'blue'"
          text-color="white"
        >
          {{$t('ONkYakuXCLvR4SfemVFHC')}} <b class="ml-1">
            {{
              100 - Math.ceil(
               (tariff.price / tariff.months)
                /
                ($root.tariffs[0].price / $root.tariffs[0].months)
                * 100
                )
               }}%&nbsp;
            (-${{ 
                  (
                      (
                        ($root.tariffs[0].price / $root.tariffs[0].months).toFixed(2) * tariff.months
                      ) - (
                        (tariff.price / tariff.months).toFixed(2) * tariff.months
                      )
                  ).toFixed(0)
                }})
          </b>
          <v-icon right>mdi-star</v-icon>
        </v-chip>

        <div class="text--primary text-left pt-3">
          <ul class="features">
            <li
              v-for="(feature, i) in tariff.features"
              :key="i"
            >
              {{ feature }}
            </li>
          </ul>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex pb-5">
        <v-spacer />

        <template>
          <v-btn
            color="primary"
            class="align-center"
            @click.prevent="openPay(tariff,i)"
          >
            {{$t('l_KNK8CoQbqOvoeVH3glt')}} ${{ tariff.price }}
          </v-btn>
        </template>

        <v-spacer />
      </v-card-actions>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          icon
          @click="modal = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>

    </v-card>

    <SelectPayModal
      :modal="selectPayShow"
      :price="selectPrice"
      :days="selectDays"
      :tariffId="selectTariffId"
      @close="selectPayShow = false"
    />

  </v-dialog>
</template>

<script>
import SelectPayModal from './SelectPayModal'
import { mapGetters } from 'vuex'

export default {
  props: ['modal'],
  components: {
    SelectPayModal
  },
  data () {
    return {
      selectPayShow: false,
      selectDays: null,
      selectPrice: null,
      selectTariffId: null,
      i: 1 // это индекс в тарифах, чтобы не переписывать условия в коде
    }
  },

  computed: {
    ...mapGetters(['user']),

    tariffs () {
      return this.$root.tariffs
    },
    tariff () {
      return this.$root.tariffs[1]
    }
  },

  methods: {
    openPay (tariff, id) {
      this.selectDays = tariff.days
      this.selectPrice = tariff.price
      this.selectTariffId = id
      this.selectPayShow = true
      this.modal = false
      return false
    }
  }
}
</script>

<style scoped>
.zoom30 {
  transform: scale(1.1);
}
.zoom60 {
  transform: scale(0.9);
}
del {
  position: relative;
  color: #000;
  text-decoration: none;
}
del:before {
  content: "";
  border-bottom: 2px solid red;
  position: absolute;
  top: 0;
  left: -10%;
  width: 120%;
  height: 50%;
  transform: rotate(-4deg);
}
.features {
  padding-left: 10px;
}
.features li {
  padding-left: 0;
  list-style: none;
}
</style>
